import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ItemList from './ItemList';
import SearchBar from './SearchBar';
import { Box, Typography, Pagination, CircularProgress } from '@mui/material';

const ItemListContainer = () => {
  const [productos, setProductos] = useState([]);
  const [filteredProductos, setFilteredProductos] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortOption, setSortOption] = useState('default');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga

  const itemsPerPage = 12;

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const response = await axios.post('https://milockers.com/api/productos.php');
        if (response.data.success) {
          let productosFiltrados = response.data.data;

          // Normalizar las categorías y eliminar duplicados
          const allCategories = [...new Set(
            response.data.data.map((prod) => prod.categoria.trim().toLowerCase())
          )];

          // Capitalizar la primera letra de cada categoría
          const capitalizedCategories = allCategories.map(
            (category) => category.charAt(0).toUpperCase() + category.slice(1)
          );

          setCategories(capitalizedCategories);
          setProductos(productosFiltrados);
          setFilteredProductos(productosFiltrados);
        } else {
          setError(response.data.message);
          console.error('Error en la respuesta de la API:', response.data.message);
        }
      } catch (error) {
        setError('Error al obtener los productos: ' + error.message);
        console.error('Error al obtener los productos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductos();
  }, []);

  useEffect(() => {
    let filteredData = productos.filter((item) =>
      item.titulo.toLowerCase().includes(searchText.toLowerCase().trim()) ||
      item.descripcion.toLowerCase().includes(searchText.toLowerCase().trim())
    );

    if (sortOption === 'titleAsc') {
      filteredData.sort((a, b) => a.titulo.localeCompare(b.titulo));
    } else if (sortOption === 'titleDesc') {
      filteredData.sort((a, b) => b.titulo.localeCompare(a.titulo));
    } else if (sortOption === 'priceAsc') {
      filteredData.sort((a, b) => a.precio - b.precio);
    } else if (sortOption === 'priceDesc') {
      filteredData.sort((a, b) => b.precio - a.precio);
    }

    if (selectedCategory) {
      filteredData = filteredData.filter((item) => item.categoria === selectedCategory);
    }

    if (selectedBrands.length > 0) {
      filteredData = filteredData.filter((item) => selectedBrands.includes(item.marca));
    }

    setFilteredProductos(filteredData);
    setPage(1); // Reiniciar a la primera página cuando se filtran los productos
  }, [searchText, sortOption, productos, selectedCategory, selectedBrands]);

  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
  };

  const handleBrandChange = (e) => {
    const brand = e.target.value;
    setSelectedBrands((prevSelectedBrands) =>
      prevSelectedBrands.includes(brand)
        ? prevSelectedBrands.filter((b) => b !== brand)
        : [...prevSelectedBrands, brand]
    );
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0); // Desplazarse instantáneamente a la parte superior de la página
  };

  const paginatedProductos = filteredProductos.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Box>
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        sortOption={sortOption}
        setSortOption={setSortOption}
        selectedBrands={selectedBrands}
        handleBrandChange={handleBrandChange}
        categories={categories}
        selectedCategory={selectedCategory}
        handleCategoryChange={handleCategoryChange}
      />
      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: 'bold',
          marginTop: '1rem',
          textAlign: 'center',
          fontFamily: 'Outfit, sans-serif', // Aplica la familia de fuente
        }}
      >
        Productos
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && (
            <Typography color="error" variant="h6" component="p" sx={{ textAlign: 'center' }}>
              {error}
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center' }}>
            <ItemList productos={paginatedProductos} />
          </Box>
          {filteredProductos.length > itemsPerPage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <Pagination
                count={Math.ceil(filteredProductos.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                color="primary"
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ItemListContainer;

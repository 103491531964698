import React, { useState } from 'react'; 
import styled from 'styled-components'; 
import { Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton'; 
import Button from '@mui/material/Button'; 
import Drawer from '@mui/material/Drawer'; 
import Box from '@mui/material/Box'; 
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List'; 
import ListItem from '@mui/material/ListItem'; 
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Carito from './Compra/Carito'; 
import Milockers from '../Image/logo.png'; 
import carritoImg from '../Image/CARRITO.svg'; 
import { Typography } from '@mui/material';
// import usuarioImg from '../Image/usuario.svg';

// Estilo para el contenedor de navegación
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #FED898;
  color: white;
`;

// Estilo para el botón del logo
const LogoButton = styled(Link)`
  height: 110px;
  background-image: url(${Milockers});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;

// Estilo para el botón de cancelar (cerrar menú)
const CancelButton = styled(Button)`
  && {
    color: white;
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
  }
`;

// Estilo para el menú de navegación (oculto en pantallas pequeñas)
const Menu = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Estilo para los ítems del menú (botones)
const MenuItem = styled(Button)`
  && {
    color: #37424F;
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
  }
`;

// Estilo para el icono de menú (hamburguesa) (visible en pantallas pequeñas)
const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  margin-right: auto;

  @media (max-width: 768px) {
    display: block;
  }
`;

// Estilo para el contenedor de los iconos (usuario y carrito)
const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Navbar = ({ fixed }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false); 
  const [menuOpen, setMenuOpen] = useState(false);

  // Función para abrir o cerrar el cajón del carrito
  const toggleDrawer = (newOpen) => () => {
    setIsOpen(newOpen);
  };

  // Función para abrir o cerrar el cajón del menú
  const toggleMenuDrawer = (newOpen) => () => {
    setMenuOpen(newOpen);
  };

  // Verifica si la página actual es la de confirmación
  const isConfirmationPage = location.pathname === '/confirmacion';

  // Componente para el cajón del carrito
  const CarritoComponent = (
    <Box sx={{ width: 250 }} role="presentation">
      <Grid container style={{ textAlign: 'center', justifyContent: 'space-between', padding: '1.3rem' }}>
        <Typography
            variant="h3"
            sx={{
              fontSize: '1.5rem',
              fontFamily: 'Outfit, sans-serif',
              fontWeight: 'bold',
              marginBottom: '1rem',
            }}
          >
            Tu carro
          </Typography>        
        <Button onClick={toggleDrawer(false)} sx={{ color: 'black' }}><CloseIcon /></Button>
      </Grid>
      <Divider />
      <Carito toggleDrawer={toggleDrawer} />
    </Box>
  );

  // Componente para la lista de navegación en el cajón del menú
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleMenuDrawer(false)}>
      <List>
        {['productos', 'contacto'].map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component={Link} to={`/${text}`}>
              <ListItemText primary={text.charAt(0).toUpperCase() + text.slice(1).replace('-', ' ')} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <Nav fixed={fixed}>
      {/* Renderiza un diseño diferente si estamos en la página de confirmación */}
      {isConfirmationPage ? (
        <>
          <LogoButton 
            onClick={() => window.location.href = '/'} 
          />
            <CancelButton
              variant="outlined"
              onClick={() => window.location.href = '/'}
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400,                  
                textAlign: 'center',              
                textJustify: 'inter-word',         
              }}
            >
              Seguir comprando
            </CancelButton>
        </>
      ) : (
        <>
          <Hamburger onClick={toggleMenuDrawer(true)}>
            <MenuIcon style={{ color: 'white' }} />
          </Hamburger>
            <LogoButton 
              onClick={() => window.location.href = '/'} 
            />
          <Menu>
            {/* Menú de navegación visible en pantallas grandes */}
            <MenuItem 
              component={Link} 
              to="/productos" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px', 
                '&:hover': {
                  backgroundColor: '#5796D0', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Productos
            </MenuItem>

            <MenuItem 
              component={Link} 
              to="/pedidos" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#5796D0', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Fabricacion a pedido
            </MenuItem>

              <MenuItem 
                component={Link} 
                to="/contacto" 
                sx={{ 
                  fontFamily: 'Outfit, sans-serif', 
                  fontWeight: 400, 
                  color: 'black',
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: '#5796D0', 
                    color: 'white'
                  },
                  textDecoration: 'none'
                }}
              >
                Contacto
              </MenuItem>

            {/* <MenuItem 
              component={Link} 
              to="/blog" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Blog
            </MenuItem> */}

          </Menu>
          <IconContainer>
            {/* Iconos para usuario y carrito */}
            {/* <IconButton component={Link} to="/iniciar-sesion" style={{ color: 'white' }}>
              <img src={usuarioImg} alt="Iniciar Sesión" style={{ width: '30px', height: '30px' }} />
            </IconButton> */}
            <IconButton
              onClick={toggleDrawer(true)}
              style={{ padding: 8 }}
              aria-label="Carrito"
            >
              <img 
                src={carritoImg} 
                alt="Carrito" 
                style={{ width: '30px', height: '30px', filter: 'invert(1)' }}
              />
            </IconButton>
          </IconContainer>
          {/* Cajón del carrito */}
          <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
            {CarritoComponent}
          </Drawer>
          {/* Cajón del menú */}
          <Drawer anchor="left" open={menuOpen} onClose={toggleMenuDrawer(false)}>
            {DrawerList}
          </Drawer>
        </>
      )}
    </Nav>
  );
};

export default Navbar;

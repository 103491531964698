import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ItemDetail from './ItemDetail';
import { useParams } from 'react-router-dom';
import { Container, Typography, CircularProgress, Box } from '@mui/material';

const ItemDetailContainer = () => {
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.post('https://milockers.com/api/producto_detalle.php', { id });
        if (response.data.success) {
          // console.log(response.data.data); // Verificar que el item tenga la propiedad stock
          setItem(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError('Error al obtener el producto: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
  
    if (id) {
      fetchItem();
    }
  }, [id]);
  
  return (
    <Container>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && (
            <Typography color="error" variant="h6" component="p" sx={{ textAlign: 'center' }}>
              {error}
            </Typography>
          )}
          {item ? <ItemDetail item={item} /> : (
            <Typography variant="h6" component="p" sx={{ textAlign: 'center' }}>
              No se encontró el producto
            </Typography>
          )}
        </>
      )}
    </Container>
  );
};

export default ItemDetailContainer;

import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const CartContext = createContext();

const carritoInicial = JSON.parse(localStorage.getItem("carrito")) || [];

export const CartProvider = ({ children }) => {
  const [carrito, setCarrito] = useState(carritoInicial);
  const [productos, setProductos] = useState({}); // Nuevo estado para almacenar productos y su stock

  useEffect(() => {
    // Fetch inicial de productos con stock
    const fetchProductos = async () => {
      try {
        const response = await axios.get('https://milockers.com/api/productos.php'); // Asegúrate de tener una API para obtener productos
        if (response.data.success) {
          // Convertir array de productos en un objeto con id como clave
          const productosObj = response.data.data.reduce((obj, producto) => {
            obj[producto.id] = producto;
            return obj;
          }, {});
          setProductos(productosObj);
        }
      } catch (error) {
        console.error('Error al obtener productos:', error);
      }
    };

    fetchProductos();
  }, []);

  const agregarAlCarrito = (item, cantidad) => {
    const stockDisponible = productos[item.id]?.stock || 0;
    const cantidadActual = carrito.reduce((acc, producto) => {
      if (producto.id === item.id) {
        return acc + producto.cantidad;
      }
      return acc;
    }, 0);

    if (cantidadActual + cantidad > stockDisponible) {
      return { success: false, message: 'No se puede añadir más de lo disponible en stock' };
    }

    setCarrito((prevCarrito) => {
      const itemAgregado = { ...item, cantidad };
      const nuevoCarrito = [...prevCarrito];
      const estaEnElCarrito = nuevoCarrito.find((producto) => producto.id === itemAgregado.id);

      if (estaEnElCarrito) {
        estaEnElCarrito.cantidad += cantidad;
        return nuevoCarrito;
      } else {
        return [...nuevoCarrito, itemAgregado];
      }
    });

    return { success: true };
  };

  const cantidadEnCarrito = () => {
    return carrito.reduce((acc, prod) => acc + prod.cantidad, 0);
  };

  const precioTotal = () => {
    return carrito.reduce((acc, prod) => acc + prod.precio * prod.cantidad, 0);
  };

  const vaciarCarrito = () => {
    setCarrito([]);
  };

  const eliminarProducto = (idProducto) => {
    setCarrito((prevCarrito) => {
      const nuevoCarrito = prevCarrito.map((producto) => {
        if (producto.id === idProducto) {
          if (producto.cantidad > 1) {
            return { ...producto, cantidad: producto.cantidad - 1 };
          } else {
            return null;
          }
        }
        return producto;
      });

      return nuevoCarrito.filter((producto) => producto !== null);
    });
  };

  const carritoFiltrado = () => {
    return carrito.map(({ id, titulo, precio, cantidad, coste_adicional, categoria }) => ({
      id,
      titulo,
      precio,
      cantidad,
      coste_adicional,
      categoria,
    }));
  };

  useEffect(() => {
    localStorage.setItem("carrito", JSON.stringify(carrito));
  }, [carrito]);

  return (
    <CartContext.Provider
      value={{
        carrito,
        agregarAlCarrito,
        cantidadEnCarrito,
        precioTotal,
        vaciarCarrito,
        eliminarProducto,
        carritoFiltrado // Exportar la nueva función
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

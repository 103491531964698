import React, { useEffect } from 'react';
import Loader from './Loader'; // Asegúrate de que Loader esté en la misma carpeta o ajusta la ruta
import { useNavigate } from 'react-router-dom'; // Si usas React Router

function Redirector() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token_ws');

    if (token) {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://milockers.com/api/Webpay/Confirmacion.php';

      const tokenInput = document.createElement('input');
      tokenInput.type = 'hidden';
      tokenInput.name = 'token_ws';
      tokenInput.value = token;

      form.appendChild(tokenInput);
      document.body.appendChild(form);
      form.submit();
    } else {
      // Si el token no se recibe, redirigimos a la página de compra fallida
      navigate('/rechazado-webpay');
    }
  }, [navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <Loader />
      <p>Redireccionando...</p>
    </div>
  );
}

export default Redirector;

import React from 'react';
import Typography from '@mui/material/Typography';
import NearMeIcon from '@mui/icons-material/NearMe';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

const Datos = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const styles = {
    container: {
      backgroundColor: '#212529',
      color: 'white',
      padding: '10px 20px',
      textAlign: 'left',
    },
    section: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: isSmallScreen ? 'center' : 'flex-start',
      marginBottom: isSmallScreen ? '10px' : '0',
    },
    icon: {
      marginRight: '10px',
    },
  };

  return (
    <div style={styles.container}>
      <Grid container spacing={isSmallScreen ? 1 : 12} justifyContent="center">
        {/* Sección de ubicación */}
        <Grid item xs={12} sm={4}>
          <div style={styles.section}>
            <NearMeIcon style={styles.icon} />
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400,
              }}
            >
              Central Gonzalo Pérez Llona 1255
            </Typography>
          </div>
        </Grid>

        {/* Sección de correo electrónico*/}
        <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
          <div style={styles.section}>
            <MailIcon style={styles.icon} />
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400,
              }}
            >
              milockers@gmail.com / contacto@milockers.com
            </Typography>
          </div>
        </Grid>

        {/* Sección de contacto telefónico (alineado a la izquierda) */}
        <Grid item xs={12} sm={4}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <PhoneIcon style={styles.icon} />
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400,
              }}
            >
              +56 9 9819 0968
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Datos;

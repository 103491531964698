import React, { useEffect } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

function CompraFallida() {
  useEffect(() => {
    // Solicita el token al servidor
    fetch('https://milockers.com/api/Webpay/getToken.php')
      .then(response => response.json())
      .then(data => {
        if (data.token_ws) {
          console.log("Token en CompraFallida:", data.token_ws); // Muestra el token en la consola
        } else {
          console.log("Error:", data.error);
        }
      })
      .catch(error => console.error("Error al obtener el token:", error));
  }, []);

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <ErrorIcon color="error" style={{ fontSize: 80, marginBottom: 20 }} />
        <Typography variant="h4" component="h1" gutterBottom>
          Compra Fallida
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Hubo un problema con tu transacción. Por favor, intenta nuevamente.
        </Typography>
        <Button variant="contained" color="primary" href="/" sx={{ mt: 2 }}>
          Volver a la página principal
        </Button>
      </Box>
    </Container>
  );
}

export default CompraFallida;

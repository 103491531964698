import React, { useEffect } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function CompraExitosa() {
  useEffect(() => {
    // Solicita el token al servidor
    fetch('https://milockers.com/api/Webpay/getToken.php')
      .then(response => response.json())
      .then(data => {
        if (data.token_ws) {
          console.log("Token en CompraExitosa:", data.token_ws); // Muestra el token en la consola
        } else {
          console.log("Error:", data.error);
        }
      })
      .catch(error => console.error("Error al obtener el token:", error));
  }, []);

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CheckCircleIcon color="success" style={{ fontSize: 80, marginBottom: 20 }} />
        <Typography variant="h4" component="h1" gutterBottom>
          ¡Compra Exitosa!
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Tu transacción ha sido aprobada. Gracias por tu compra.
        </Typography>
        <Button variant="contained" color="primary" href="/" sx={{ mt: 2 }}>
          Volver a la página principal
        </Button>
      </Box>
    </Container>
  );
}

export default CompraExitosa;
